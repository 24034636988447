import Modal from '@berlitz/modal'
import * as React from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { CenterText, Overrides, StyledP } from './style'
import EmptyState from '@components/EmptyState'
import Spinner from '@berlitz/spinner'
import { autoEnrollmentsByCode, CodeId } from './AutoEnrollmentCode'
import { useLazyQuery } from 'react-apollo'
import * as queryString from 'query-string'
import { useSelector } from 'react-redux'
import { StoreProps } from '@interfaces/StoreState'
import WebToCase from '@components/WebToCase'
import { GetUserProfile } from '@interfaces/UserProfile'
import { GET_USER_PROFILE } from '@queries/userProfile'
import { PROFILE_TYPE } from '@utils/constants'

interface EAPModalContentProps {
  onClose: Function
  onCancel?: Function
  onProceed?: Function
  title?: string
  subtitle?: React.ReactNode
  description?: React.ReactNode
  proceedLabel?: string
  cancelLabel?: string
  noButtons?: boolean
  anApiError?: boolean
  customButtons?: React.ReactNode
  cancelBtnType?: 'info' | 'warning' | 'error' | 'success' | 'light' | 'default' | undefined
  proceedBtnType?: 'info' | 'warning' | 'error' | 'success' | 'light' | 'default' | undefined
  cancelOutline?: boolean
  img?: string
  loading?: boolean
  hideCloseButton?: boolean
  code: string
}

interface EAPModalProps extends EAPModalContentProps {
  open: boolean
}

interface Action {
  linkUrl: string
  btnTxt: string
  onProceed?: Function
}

export const BodyText: React.FC<{ description: string }> = ({ description = '' }) => {
  return (
    description && (
      <StyledP>
        <FormattedMessage id={description} />
      </StyledP>
    )
  )
}

export const RaiseACaseModalContent: React.FC<EAPModalProps & WrappedComponentProps> = ({
  subtitle,
  description,
  onClose,
}) => {
  const { product_definition_id } = queryString.parse(window.location.search)
  const { StudentFirstName, StudentLastName, StudentEmail } = useSelector(({ session }: StoreProps) => {
    const SSOFederatedIdentityId =
      session?.user?.signInUserSession?.idToken?.payload?.['custom:federated_override'] ??
      session?.user?.signInUserSession?.idToken?.payload?.identities?.[0]?.providerName
    const StudentFirstName = session?.user?.signInUserSession?.idToken?.payload?.given_name
    const StudentLastName = session?.user?.signInUserSession?.idToken?.payload?.family_name
    const StudentEmail = session?.user?.signInUserSession?.idToken?.payload?.email
    const StudentExternalId =
      session?.user?.signInUserSession?.idToken?.payload?.['custom:sub_override'] ??
      session?.user?.signInUserSession?.idToken?.payload?.['custom:externalUserId']

    return {
      SSOFederatedIdentityId,
      StudentFirstName,
      StudentLastName,
      StudentEmail,
      StudentExternalId,
    }
  })

  const extDescription = `${(subtitle as string) ?? (description as string)} | ${product_definition_id}`
  return (
    <>
      <WebToCase
        extEmail={StudentEmail}
        extFullname={`${StudentFirstName} ${StudentLastName}`}
        extCourseType="Online"
        extDescription={extDescription}
        onClose={onClose as () => void}
      />
    </>
  )
}
export const WarningModalContent: React.FC<EAPModalProps & WrappedComponentProps> = ({
  title,
  subtitle,
  description,
  img,
  onProceed,
  code,
}) => {
  const ActionButtonByCode = new Map<string, Action>()
  ActionButtonByCode.set('Default', {
    linkUrl: '/support',
    btnTxt: 'Raise a case',
    onProceed,
  })

  ActionButtonByCode.set(CodeId.CUSTOM_RESTRICTED_ACCESS, {} as Action)

  ActionButtonByCode.set(CodeId.ALREADY_ENROLLED_SAME_LANGUAGE, {
    linkUrl: '/',
    btnTxt: 'Go to My Berlitz',
    onProceed: () => (location.href = '/'),
  })

  ActionButtonByCode.set(CodeId.ALREADY_ENROLLED_DIFFERENT_LANGUAGE, {
    linkUrl: '/',
    btnTxt: 'Go to My Berlitz',
    onProceed: () => (location.href = '/'),
  })

  let actions = ActionButtonByCode.get(code)
  if (!actions) {
    actions = ActionButtonByCode.get('Default')!
  }

  return (
    <>
      <CenterText>
        <EmptyState
          heading={title}
          customBodyText={<BodyText description={(subtitle ?? description) as string} />}
          img={img}
          top={top}
          {...actions}
        />
      </CenterText>
    </>
  )
}

export const LoadingContent: React.FC<EAPModalProps & WrappedComponentProps> = ({
  title,
  subtitle,
  description,
  img,
}) => (
  <>
    <CenterText>
      <EmptyState heading={title} bodyTxt={subtitle ?? description} img={img} top={top}>
        <Spinner />
      </EmptyState>
    </CenterText>
  </>
)

const EAPModal: React.FC<EAPModalProps & WrappedComponentProps> = (props) => {
  const [code, setCode] = React.useState(props.code)

  const [raiseCase, setRaiseCase] = React.useState(false)
  const { Code, Message, Image } =
    autoEnrollmentsByCode.get(code) ?? autoEnrollmentsByCode.get(CodeId.ENROLLMENT_ERROR)!

  const onClose = code === CodeId.ENROLLMENT_INITIALIZED || raiseCase ? () => {} : props.onClose
  const hideCloseButton = code === CodeId.ENROLLMENT_INITIALIZED || raiseCase
  const showLoaderModal = code === CodeId.ENROLLMENT_INITIALIZED

  const { product_definition_id } = queryString.parse(window.location.search)
  const { SSOFederatedIdentityId, StudentFirstName, StudentLastName, StudentEmail, StudentExternalId } = useSelector(
    ({ session }: StoreProps) => {
      const SSOFederatedIdentityId =
        session?.user?.signInUserSession?.idToken?.payload?.['custom:federated_override'] ??
        session?.user?.signInUserSession?.idToken?.payload?.identities?.[0]?.providerName
      const StudentFirstName = session?.user?.signInUserSession?.idToken?.payload?.given_name
      const StudentLastName = session?.user?.signInUserSession?.idToken?.payload?.family_name
      const StudentEmail = session?.user?.signInUserSession?.idToken?.payload?.email
      const StudentExternalId =
        session?.user?.signInUserSession?.idToken?.payload?.['custom:sub_override'] ??
        session?.user?.signInUserSession?.idToken?.payload?.['custom:externalUserId']

      return {
        SSOFederatedIdentityId,
        StudentFirstName,
        StudentLastName,
        StudentEmail,
        StudentExternalId,
      }
    }
  )

  /**
   * Raise a case
   */
  const onProceed = () => {
    setRaiseCase(true)
  }

  /**
   * Auto Enrollment Mutation
   */
  let timeout: NodeJS.Timeout

  const [getUserProfile] = useLazyQuery<GetUserProfile>(GET_USER_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      localStorage.setItem('test', JSON.stringify(data?.getUserProfile?.StudentProfile))
      // check if data.getUserProfile.StudentProfile has a value
      // if it does, then the user has a program created redirect to the /
      if (data?.getUserProfile?.StudentProfile) {
        const hasAnActiveRegistration =
          data?.getUserProfile?.StudentProfile?.StudentProfileInfo?.RegistrationWrappers.some(
            (registration) => registration.RegistrationInfo.Status === 'Active'
          )
        if (hasAnActiveRegistration) {
          clearInterval(timeout)
          StudentEmail && localStorage.removeItem(`EAP-${StudentEmail}`)
          location.href = '/'
        }
      }
    },
    onError: (error) => {
      const autoEnrollment = autoEnrollmentsByCode.get(CodeId.ENROLLMENT_ERROR)!
      autoEnrollmentsByCode.set(CodeId.ENROLLMENT_ERROR, {
        ...autoEnrollment,
        Code: error?.message,
        Message: autoEnrollment.Message,
      })

      setCode(CodeId.ENROLLMENT_ERROR)
      clearInterval(timeout)
    },
  })

  React.useEffect(() => {
    timeout = setInterval(() => {
      if ([CodeId.ENROLLMENT_INITIALIZED].includes(code as CodeId)) {
        if (
          product_definition_id &&
          SSOFederatedIdentityId &&
          StudentFirstName &&
          StudentLastName &&
          StudentEmail &&
          StudentExternalId
        ) {
          // Set StudentEmail and ProductDefinitionId to localStorage
          localStorage.setItem(`EAP-${StudentEmail}`, product_definition_id as string)

          getUserProfile({
            variables: {
              Type: PROFILE_TYPE.STUDENT,
              Id: StudentEmail,
              Key: 'Email',
            },
          })
        }
      }
    }, 10000)

    return () => {
      clearInterval(timeout)
    }
  }, [code])

  /**
   * Log the event
   */
  React.useEffect(() => {
    const messageContext = {
      code,
      enrollment: autoEnrollmentsByCode.get(code),
    }
    __DEV__ && console.log('Enrollment Auto Provision Modal Opened', messageContext)
    window?.DD_LOGS?.logger?.info('Enrollment Auto Provision Modal Opened', messageContext)
  }, [code])

  return (
    <Overrides hideCloseButton={hideCloseButton}>
      <Modal open={props.open} onClose={onClose}>
        {raiseCase ? (
          <RaiseACaseModalContent
            {...props}
            title={Message}
            description={Code}
            img={Image}
            onClose={() => setRaiseCase(false)}
          />
        ) : (
          <>
            {showLoaderModal ? (
              <LoadingContent {...props} title={Message} description={Code} img={Image} />
            ) : (
              <WarningModalContent {...props} title={Message} description={Code} img={Image} onProceed={onProceed} />
            )}
          </>
        )}
      </Modal>
    </Overrides>
  )
}

export default injectIntl(EAPModal)
